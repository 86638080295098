import {backend} from "./backend";
import helpers from "../assets/js/helpers";

const cadastrar = async (formulario) => {
    let uri = "api/pacotes";
    let dados = traduzirFormularioPacote(formulario);
    let response = await backend.post(uri, dados, helpers.montarHeaderToken());
    return response;
};

const editar = async (id, formulario) => {
    let uri = `api/pacotes/${id}`;
    let dados = traduzirFormularioPacote(formulario);
    let response = await backend.put(uri, dados, helpers.montarHeaderToken());
    return response;
};

const excluir = async (id) => {
    let uri = `api/pacotes/${id}`;
    let response = await backend.delete(uri, helpers.montarHeaderToken());
    return response;
};
const adicionarTaxaSetup = async (payload, pacoteId) => {
    let uri = `api/pacotes/${pacoteId}/taxas-setup`;
    return await backend.post(uri, payload, helpers.montarHeaderToken());
}
const removerTaxaSetup = async (taxa) => {
    let uri = `api/pacotes/taxas-setup/${taxa.id}`;
    return await backend.delete(uri, helpers.montarHeaderToken());
}

const listarTaxasSetup = async (pacoteId) => {
    let uri = `api/pacotes/${pacoteId}/taxas-setup`;
    return await backend.get(uri, helpers.montarHeaderToken());
}
export default {
    cadastrar,
    editar,
    excluir,
    adicionarTaxaSetup,
    removerTaxaSetup,
    listarTaxasSetup,
};



const traduzirFormularioPacote = (formulario) => {
    return {
        nome: formulario.nome,
        tipo_pagamento: formulario.tipoPagamento,
        porcentagem: helpers.monetarioBrasilParaNumerico(formulario.porcentagem),
        quantidade_parcelas: formulario.quantidadeParcelas,
        parcelas_garantia: formulario.parcelasGarantia,
        parcelas_reforma: formulario.parcelasReforma,
        comissao_taxa_setup: parseFloat(formulario.comissaoTaxaSetup),
        comissao_pacote: parseFloat(formulario.comissaoPacote),
        habilitado: formulario.habilitado,
    }
};
