<template>
	<v-dialog v-model="aberto" max-width="320">
		<v-card>
			<v-card-text class="text-center pt-5">
				<v-icon class="font-size-80" :color="corIcone" v-text="icone" x-large />
			</v-card-text>

			<v-card-text v-text="mensagemDialog" class="text-center font-size-20" />

			<v-card-text class="text-center font-size-14" v-if="modelo === 'excluirEmpresa'">
				<div>A cancelamento de uma empresa causará a cancelamento automático dos usuário nela cadastrados</div>
			</v-card-text>

			<v-card-actions class="py-4">
				<v-btn color="red lighten-1" @click="aberto = false" class="px-5 mr-10" depressed>
					<span class="text-transform-none white--text">Cancelar</span>
				</v-btn>

				<v-spacer />

				<v-btn color="green lighten-1" class="px-5" @click="confirmar()" depressed>
					<span class="text-transform-none white--text">{{ mensagemBotao }}</span>
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: "DialogConfirmacao",
	mixins: [],
	components: {},
	data() {
		return {
			aberto: false,
			modelo: null,
		};
	},
	computed: {
		icone() {
			if (this.modelo === "excluirUsuario") return "mdi-delete-circle";
			if (this.modelo === "excluirEmpresa") return "mdi-delete-circle";
			if (this.modelo === "excluirPacote") return "mdi-delete-circle";
			if (this.modelo === "excluirCliente") return "mdi-delete-circle";
			return "";
		},
		corIcone() {
			if (this.modelo === "excluirUsuario") return "red lighten-1";
			if (this.modelo === "excluirEmpresa") return "red lighten-1";
			if (this.modelo === "excluirPacote") return "red lighten-1";
			if (this.modelo === "excluirCliente") return "red lighten-1";
			return "";
		},
		mensagemDialog() {
			if (this.modelo === "excluirUsuario") return "Confirmar exclusão do usuário?";
			if (this.modelo === "excluirEmpresa") return "Confirmar desativação dessa empresa?";
			if (this.modelo === "excluirPacote") return "Confirmar exclusão do pacote?";
			if (this.modelo === "excluirCliente") return "Confirmar exclusão do cliente?";
			return "";
		},
		mensagemBotao() {
			if (this.modelo === "excluirUsuario") return "Sim";
			if (this.modelo === "excluirEmpresa") return "Sim";
			if (this.modelo === "excluirPacote") return "Sim";
			if (this.modelo === "excluirCliente") return "Sim";
			return "";
		},
	},
	methods: {
		exibir() {
			this.aberto = true;
		},
		confirmar() {
			this.$emit("confirmou");
			this.aberto = false;
		},
	},
	watch: {},
	created() {},
};
</script>

<style scoped>

</style>