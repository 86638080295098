<template>
  <v-card :class="pacote.habilitado ? 'card-habilitado' : 'card-desabilitado'" class="sombra">
    <v-card-title class="py-2 cor-texto-principal">{{ pacote.nome }}</v-card-title>
    <v-card-text>
      <table width="100%" border="0" style="border-collapse: collapse;">
        <tr>
          <td class="font-weight-medium td-escura">Porcentagem do Pacote</td>
        </tr>
        <tr>
          <td>{{ pacote.porcentagem | porcentagem }}</td>
        </tr>
        <tr>
          <td class="font-weight-medium td-escura">Forma de Pagamento</td>
        </tr>
        <tr>
          <td>{{ traduzirFormapagamento(pacote) }}</td>
        </tr>
        <tr>
          <td class="font-weight-medium td-escura">Máximo de Parcelas</td>
        </tr>
        <tr>
          <td>{{ pacote.quantidadeParcelas }}</td>
        </tr>
        <tr>
          <td class="font-weight-medium td-escura">Parcelas garantidas</td>
        </tr>
        <tr>
          <td>{{ pacote.parcelasGarantia }}</td>
        </tr>
        <tr>
          <td class="font-weight-medium td-escura">Parcelas reforma</td>
        </tr>
        <tr>
          <td>{{ pacote.parcelasReforma }}</td>
        </tr>
        <tr v-if="permissaoMaster">
          <td class="font-weight-medium td-escura">Comissão da Taxa Setup</td>
        </tr>
        <tr v-if="permissaoMaster">
          <td>{{ pacote.comissaoTaxaSetup | porcentagem }}</td>
        </tr>
        <tr v-if="permissaoMaster">
          <td class="font-weight-medium td-escura">Comissão do Pacote</td>
        </tr>
        <tr v-if="permissaoMaster">
          <td>{{ pacote.comissaoPacote | porcentagem }}</td>
        </tr>
        <tr v-if="permissaoMaster">
          <td class="font-weight-medium td-escura">Status</td>
        </tr>
        <tr v-if="permissaoMaster">
          <td>{{ pacote.habilitado ? "Habilitado" : "Desabilitado" }}</td>
        </tr>
      </table>
    </v-card-text>
    <v-divider color="grey"></v-divider>
    <v-card-actions class="pa-0" v-if="permissaoMaster">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" class="w-50">
            <v-btn
                depressed
                color="transparent"
                class="w-100 pa-0 rounded-none"
                :class="{ 'cursor-disable': bloquearExclusao }"
                :disabled="bloquearExclusao"
                v-bind="attrs"
                @click="excluir()"
            >
              <v-icon color="red darken-1">mdi-delete</v-icon>
            </v-btn>
          </div>
        </template>

        <span>{{ tooltipExcluir }}</span>
      </v-tooltip>

      <v-divider color="grey" vertical></v-divider>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" class="w-50">
            <v-btn
                depressed
                color="transparent"
                class="w-100 pa-0 rounded-none"
                :class="{ 'cursor-disable': bloquearEdicao }"
                :disabled="bloquearEdicao"
                v-bind="attrs"
                @click="irParaEdicao()"
            >
              <v-icon color="blue darken-1">mdi-pencil</v-icon>
            </v-btn>
          </div>
        </template>

        <span>{{ tooltipEditar }}</span>
      </v-tooltip>
    </v-card-actions>
  </v-card>
</template>

<script>
import mixinGeral from "../../mixin/geral";

export default {
  name: "CardPacote",
  props: {
    pacote: {
      type: Object,
      required: true,
    },
  },
  mixins: [mixinGeral],
  data() {
    return {};
  },
  computed: {
    contratosQueUtilizam() {
      const idsClientes = this.clientes
          .filter((cliente) => cliente.pacoteId === this.pacote.id)
          .map((cliente) => cliente.id);
      return this.contratos.filter((contrato) => idsClientes.includes(contrato.clienteId));
    },
    bloquearEdicao() {
      return false;
      // if (this.contratosQueUtilizam.length === 0) return false;
      //
      // const filtroPagamentos =
      // 	this.contratosQueUtilizam.filter((contrato) => {
      // 		const statusPagamento = contrato.pagarmeStatus;
      // 		return ["paid", "processing", "authorized", "waiting_payment", "active"].includes(statusPagamento);
      // 	}).length > 0;
      //
      // const filtroContrato =
      // 	this.contratosQueUtilizam.filter((contrato) => {
      // 		return contrato.clicksignStatus === "enviado";
      // 	}).length > 0;
      //
      // return filtroPagamentos || filtroContrato;
    },
    bloquearExclusao() {
      if (this.contratosQueUtilizam.length === 0) return false;

      return (
          this.contratosQueUtilizam.filter((contrato) => {
            const statusContrato = contrato.clicksignStatus;
            return statusContrato === "enviado";
          }).length > 0
      );
    },
    tooltipEditar() {
      if (this.bloquearEdicao && !this.bloquearExclusao) {
        return "Pacote Contratado";
      }

      if (this.bloquearEdicao && this.bloquearExclusao) {
        return "Pacote em Processo de Contratação";
      }

      return "Editar Pacote";
    },
    tooltipExcluir() {
      if (this.bloquearExclusao) return "Pacote em Processo de Contratação";
      return "Excluir Pacote";
    },
  },
  methods: {
    traduzirFormapagamento(pacote) {
      const forma = pacote.tipoPagamento;
      if (forma === "cartao") {
        return "Cartão de Crédito";
      } else if (forma === "boleto") {
        return "Boleto Bancário";
      } else if (forma === "dinheiro") {
        return "Dinheiro";
      } else {
        return "";
      }
    },
    irParaEdicao() {
      if (this.bloquearEdicao) return;
      const id = this.pacote.id;
      return this.navegar("pacotesEditar", {id});
    },
    excluir() {
      if (this.bloquearExclusao) return;
      this.$emit("confirmarExclusao", this.pacote);
    },
  },
  watch: {},
  created() {
  },
  filters: {
    porcentagem(valor) {
      let valorBr = Number(valor).toLocaleString("pt-br", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return String(valorBr) === "NaN" ? "" : `${valorBr}%`;
    },
  },
};
</script>

<style scoped>
.cor-texto-principal {
  color: var(--cor-primaria-100);
}

.td-escura {
  background-color: #eee;
}

td {
  border: 1px solid #eee !important;
}

.card-habilitado {
  border-top: 5px solid #21BA45;
}

.card-desabilitado {
  border-top: 5px solid #E53935;
}

.sombra {
  -webkit-box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px !important;
}
</style>
