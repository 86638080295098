<template>
  <menu-principal>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <titulo-pagina>Pacotes Cadastrados</titulo-pagina>
        </v-col>

        <v-col cols="12" v-if="semPacotes">
          <bloco-erro>Não Há Pacotes Cadastrados</bloco-erro>
        </v-col>

        <v-col cols="12" v-if="!semPacotes">
          <v-card class="rounded-lg">
            <v-card-text>
              <v-row>
                <v-col>
                  <v-btn color="white" class="float-left" small @click="obterInformacoes">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                  <v-btn color="white" class="mx-3" small @click="modalFiltro = true">
                    <v-icon>mdi-filter</v-icon>
                  </v-btn>

                  <v-chip
                      v-for="(value, key) in filtros"
                      v-if="(value != null && value.length > 0)"
                      :key="key"
                      class="mx-3"
                      close
                      color="orange"
                      label
                      outlined
                      @click:close="filtros[key] = null"
                  > {{ key | primeiraLetraMaiuscula }}: {{ value }}
                  </v-chip>
                  <v-btn v-if="permissaoMaster" class="ml-3 float-right" color="orange" to="/pacotes/cadastrar" dark small>
                    <v-icon>mdi-plus</v-icon>
                    Novo Pacote
                  </v-btn>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="6" md="3" lg="3" v-for="(pacote, idx) in registrosFiltrados" :key="'pacote-'+(idx+1)">
                  <card-pacote :pacote="pacote" v-on:confirmarExclusao="confirmarExclusao($event)"/>
                </v-col>
              </v-row>

            </v-card-text>
          </v-card>

        </v-col>
      </v-row>

      <dialog-filtro
          ref="DialogFiltro"
          :aberto="modalFiltro"
          @fechar="modalFiltro = false"
          @filtrar="filtrar"
      />

      <dialog-confirmacao ref="dialogConfirmacao" v-on:confirmou="excluir()"/>

    </v-container>
  </menu-principal>
</template>

<script>
import MenuPrincipal from "../../components/MenuPrincipal";
import TituloPagina from "../../components/TituloPagina";
import CardPacote from "../../components/pacotes/CardPacote";
import BlocoErro from "../../components/BlocoErro";
import DialogConfirmacao from "../../components/DialogConfirmacao";
import mixinGeral from "../../mixin/geral";
import pacotesServices from "../../services/pacotes";
import DialogFiltro from "@/components/pacotes/DialogFiltro";
import {mapActions} from "vuex";

export default {
  name: "Index",
  mixins: [mixinGeral],
  components: {
    MenuPrincipal,
    TituloPagina,
    BlocoErro,
    CardPacote,
    DialogConfirmacao,
    DialogFiltro
  },
  data() {
    return {
      modalFiltro: false,
      idPacoteExclusao: null,
      filtros: {
        nome: null,
        tipoPagamento: null,
      },
    };
  },
  computed: {
    permissaoPagina() {
      return this.permissaoMaster;
    },
    semPacotes() {
      return this.pacotesNaoExcluidos.length === 0;
    },
    pacotesNaoExcluidos() {
      return this?.pacotes?.filter((pacote) => !pacote.excluido) ?? [];
    },
    itensTipoPagamento() {
      return [
        {
          valor: "dinheiro",
          label: "Dinheiro",
        },
        {
          valor: "cartao",
          label: "Cartão de crédito",
        },
        {
          valor: "boleto",
          label: "Boleto bancário",
        },
      ];
    },
    registrosFiltrados() {
      let filtrados = [...this.pacotesNaoExcluidos];

      const filtrandoNome = !!this.filtros.nome;
      const filtrandoTipoPagamento = !!this.filtros.tipoPagamento;

      if (filtrandoNome) filtrados = this.filtrarNome(filtrados);
      if (filtrandoTipoPagamento) filtrados = this.filtrarTipoPagamento(filtrados);

      return filtrados;
    },
  },
  methods: {
    ...mapActions({obterInformacoes: "actionObterInformacoes"}),
    confirmarExclusao(pacote) {
      this.idPacoteExclusao = pacote.id;
      this.$refs.dialogConfirmacao.modelo = "excluirPacote";
      this.$refs.dialogConfirmacao.exibir();
    },
    async excluir() {
      try {
        await pacotesServices.excluir(this.idPacoteExclusao);
        await this.obterInformacoes();
        this.notificacaoSucesso("Pacote Excluído");
      } catch (e) {
        this.interpretarErro(e);
      } finally {
        this.desativarLoading();
      }
    },
    filtrarNome(filtrados) {
      return filtrados.filter((f) => {
        return f.nome.toUpperCase().indexOf(this.filtros.nome.toUpperCase()) !== -1;
      });
    },
    filtrarTipoPagamento(filtrados) {
      return filtrados.filter((f) => {
        return f.tipoPagamento === this.filtros.tipoPagamento;
      });
    },
    filtrar(obj) {
      this.filtros = obj;
    },
  },
  watch: {},
  created() {
  },
};
</script>